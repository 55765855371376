
// get transaction

import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ListParams } from "src/models/common";
import { formatDateToUTCZeroTime } from "src/utils/newUtils/formatDate";

interface IDataListAgency {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IListAgencyState {
    data: IDataListAgency;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
}

export const initialListAgencyState: IListAgencyState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        searchText: '',
        orderBy: '',
        orderDirection: '',
        startDate: formatDateToUTCZeroTime(
            new Date().setFullYear(new Date().getFullYear() - 1)
        ),
        endDate: formatDateToUTCZeroTime(new Date(), true),
    },
    pagination: {
        totalCount: 0,
    }
}

