import { formatDateToUTCZeroTime } from 'src/utils/newUtils/formatDate';
import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';
import { PAGE_DEFAULT } from './../../../contants/pagination';
interface IDataListNotification {
  status: number;
  data: {
    items: any[];
    totalCount: number;
  };
  message: string;
}

export interface IListNotificationState {
  data: IDataListNotification;
  filter: any;
  isFetching: boolean;
  pagination: any;
}

export const initialListNotificationState: IListNotificationState = {
  data: {
    data: {
      items: [],
      totalCount: 0,
    },
    message: '',
    status: 0,
  },
  filter: {
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    searchText: '',
    languageId: null,
    startDate: formatDateToUTCZeroTime(
      new Date().setDate(new Date().setFullYear(new Date().getFullYear() - 1))
    ),
    endDate: formatDateToUTCZeroTime(new Date(), true),
  },
  isFetching: false,
  pagination: {
    totalCount: 0,
  },
};
