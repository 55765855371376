import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { ListParams } from 'src/models/common';
import { formatDateToUTCZeroTime } from 'src/utils/newUtils/formatDate';

interface IDataListAnnouncements {
  data: {
    items: any[];
    total: number;
  };
  error: any;
  success: boolean;
}

export interface IListAnnouncementsState {
  data: IDataListAnnouncements;
  isFetching: boolean;
  filter: ListParams;
  pagination: Object;
  successCount: number;
}

export const initialListAnnouncementsState: IListAnnouncementsState = {
  data: {
    data: {
      items: [],
      total: 0,
    },
    error: null,
    success: false,
  },
  filter: {
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    searchText: '',
    orderBy: '',
    orderDirection: '',
    startDate: formatDateToUTCZeroTime(
      new Date().setFullYear(new Date().getFullYear() - 1)
    ),
    endDate: formatDateToUTCZeroTime(new Date(), true),
  },
  pagination: 0,
  isFetching: false,
  successCount: 0,
};
