import { formatDateToUTCZeroTime } from 'src/utils/newUtils/formatDate';
import { PAGE_SIZE_DEFAULT } from './../../../../../contants/pageSize';
import { ListParams } from "src/models/common";

interface IDataListAnnouncementsCategory {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListAnnouncementsCategoryState {
    data: IDataListAnnouncementsCategory;
    isFetching: boolean,
    filter: Object,
    pagination: Object,
}

export const initialListAnnouncementsCategoryState: IListAnnouncementsCategoryState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        startDate: formatDateToUTCZeroTime(
            new Date().setFullYear(new Date().getFullYear() - 1)
        ),
        endDate: formatDateToUTCZeroTime(new Date(), true),
    },
    pagination: 0,
    isFetching: false,
}

