import { put, takeEvery, debounce } from 'redux-saga/effects';
import { HandleBlockOfferTypes } from '../../actions/handleBlockOffer';
import {
  handleBlockOfferFetching,
  handleBlockOfferSuccess,
  handleBlockOfferError,
  handleBlockOfferFilter,
} from '../../actions/handleBlockOffer/action';
import { handleBlockOffer } from 'src/apis/service/CryptoExchange';
import { toastSuccess, toastError } from 'src/helpers/toastHelper';
function* handleBlockOfferSaga(action: any): Generator<any> {
  yield put(handleBlockOfferFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleBlockOffer(payload);
    if (data && data?.status === 200) {
      toastSuccess(
        payload?.status === 2
          ? 'bo_block_offer_success_label'
          : 'bo_unblock_offer_success_label'
      );
      yield put(handleBlockOfferSuccess(data));
    } else {
      toastError(
        payload?.status === 2
          ? 'bo_block_offer_fail_label'
          : 'bo_unblock_offer_fail_label'
      );
      yield put(handleBlockOfferError('error'));
    }
  } catch (error) {
    toastError(
      payload?.status === 2
        ? 'bo_block_offer_fail_label'
        : 'bo_unblock_offer_fail_label'
    );
    yield put(handleBlockOfferError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(handleBlockOfferFilter(action.payload));
}
export function* watchHandleBlockOffer() {
  yield takeEvery(
    HandleBlockOfferTypes.HANDLE_BLOCK_OFFER,
    handleBlockOfferSaga
  );
  yield debounce(
    500,
    HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
